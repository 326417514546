import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const FeatureContent2 = [
  {
    icon: "78",
    title: "Easy Customizable",
    subTitle: `Duis aute irure dolor reprehen derit in voluptat velit.`,
    hoverText: `Bring all team slit managment task quis togather.`,
    altName: "Screen 1",

  },
  {
    icon: "79",
    title: "Modern & Slick",
    subTitle: `Duis aute irure dolor reprehen derit in voluptat velit.`,
    hoverText: `  Bring all team slit managment task quis togather.`,
  },
  {
    icon: "80",
    title: "Trusted & Secure",
    subTitle: `Duis aute irure dolor reprehen derit in voluptat velit.`,
    hoverText: `  Bring all team slit managment task quis togather.`,
  },
  {
    icon: "80",
    title: "Trusted & Secure",
    subTitle: `Duis aute irure dolor reprehen derit in voluptat velit.`,
    hoverText: `  Bring all team slit managment task quis togather.`,
  },
  {
    icon: "80",
    title: "Trusted & Secure",
    subTitle: `Duis aute irure dolor reprehen derit in voluptat velit.`,
    hoverText: `  Bring all team slit managment task quis togather.`,
  },
];

// const FeatureContent = [
//   {
//     img: "img_73",
//     altName: "Screen 1",
//   },
//   {
//     img: "img_74",
//     altName: "Screen 2",
//   },
//   {
//     img: "img_75",
//     altName: "Screen 3",
//   },
//   {
//     img: "img_76",
//     altName: "Screen 4",
//   },
//   {
//     img: "img_75",
//     altName: "Screen 5",
//   },
//   {
//     img: "img_76",
//     altName: "Screen 6",
//   },
//   {
//     img: "img_73",
//     altName: "Screen 7",
//   },
// ];

const FeatureContent = () => {
  const settings = {
    dots: false,
    draggable: false,
    arrow: true,
    infinite: false,
    speed: 900,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
    ],

  };

  return (
    <>
      <Slider {...settings}>
        {FeatureContent2.map((val, i) => (
          <div className="item" key={i}>
            <div className="block-style-seventeen extra-margin">
              <div className="icon d-flex align-items-center justify-content-center">
                <img src={`images/icon/${val.icon}.svg`} alt="icon" />
              </div>
              <div className="static-text">
                <h3>{val.title}</h3>
                <p>{val.subTitle}</p>
              </div>
              <div className="hover-text">{val.hoverText}</div>
            </div>

            {/*<div className="img-meta">*/}
            {/*  <img src={`images/media/${val.img}.png`} alt={val.altName} />*/}
            {/*</div>*/}
          </div>
        ))}
      </Slider>
    </>
  );
};

export default FeatureContent;
